@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply h-screen w-full bg-grainy bg-cover bg-fixed font-poppins;
}

#root {
  @apply 2xl:container 2xl:mx-auto;
}
