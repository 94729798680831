.nav {
  transform: translateX(100%);
  transition: transform 0.5s;
}

.nav.click {
  transform: translateX(0);
}

.arrow-animation {
  animation: paletteAnimation 2s infinite;
}

.fade-in {
  animation: fadeIn 0.8s forwards;
}

.animation-button {
  transition: all 0.2s ease-in-out;
}

.button-item {
  @apply hover:scale-[0.95];
}

.fadein-left {
  animation: fadeInLeft 1s forwards;
}

.fadein-right {
  animation: fadeInRight 1s forwards;
}

.fadein-down {
  animation: fadeInDown 0.4s forwards;
}

@keyframes paletteAnimation {
  0%,
  100% {
    transform: translate(0, -10px);
  }

  50% {
    transform: translate(0px, 10px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
